<template>
  <v-card style="width: 100%"
    class="py-12" flat color="#F0F6FF">
    <div style="max-width: 1500px" class="mx-auto text-center">
        <span class="px-6 mb-1 font-rubik font-weight-medium" style="font-size: 25px">
          La nostra comunitat
        </span>
      <v-slide-group
        v-model="story"
        class="px-4 ma-0 mt-6"
        :show-arrows="false"
      >
        <v-slide-item
          v-for="(item,n) in items"
          :key="n"

        >
          <v-card
            color="grey lighten-1"
            class="ma-2  shadow-sm"
            height="320"
            width="200"
            @click="stories = true, story=n"
          >
            <v-img :src="item" height="100%"></v-img>
          </v-card>
        </v-slide-item>
      </v-slide-group>

      <p class="mb-3 mt-8">Envia'ns fotos del teu lloc d'estudi a
        <span style="color: #3d5afe; background-color: #CCF3FF"
              class="py-2 pl-1 pr-2 ml-1 rounded-lg font-weight-medium">
          @examenselectivitat
          </span>
      </p>

    </div>


    <v-dialog
      v-model="stories" value="true" width="420" max-width="100%" style="max-height: 100%">
      <v-card height="100%">

        <v-icon
          @click="stories=false"
          style="position: absolute; right: 20px; top: 20px"
        >
          {{ mdiClose }}
        </v-icon>

        <v-responsive aspect-ratio="0.6">
          <v-img class="ig-stories"
                 :src="items[story]"
                 height="100%"
          />

          <div
            style="width: 100%; height: 40px; position: absolute; left: 0; top: calc(50% - 30px); z-index: 4"
            class="d-flex px-2">
            <v-btn
              v-if="story > 0"
              @click="story--"
              fab small>
              <v-icon large>{{
                  mdiChevronLeft
                }}
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="story < items.length-1"
              @click="story++"
              fab small>
            <v-icon large>{{
                mdiChevronRight
              }}
            </v-icon>
            </v-btn>
          </div>

        </v-responsive>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {mdiClose} from "/src/assets/mdi.json";
import {mdiChevronLeft} from "/src/assets/mdi.json";
import {mdiChevronRight} from "/src/assets/mdi.json";

export default {
  name: "DossiersImages",
  data() {
    return {
      mdiClose: mdiClose,
      mdiChevronLeft: mdiChevronLeft,
      mdiChevronRight: mdiChevronRight,
      items: [
        "https://examenselectivitat.cat:3000/api/images/other/IMG_5075.jpg",
        "https://examenselectivitat.cat:3000/api/images/other/IMG_5223.jpg",
        "https://examenselectivitat.cat:3000/api/images/other/F80F1A44-2A83-498B-B968-530D4DADC116.JPG",
        "https://examenselectivitat.cat:3000/api/images/other/IMG_5108.jpg",
        "https://examenselectivitat.cat:3000/api/images/other/dossiers.JPG",
        "https://examenselectivitat.cat:3000/api/images/other/IMG_5102.jpg",
        "https://examenselectivitat.cat:3000/api/images/other/IMG_5081.jpg",
        "https://examenselectivitat.cat:3000/api/images/other/IMG_1365.jpg",
        "https://examenselectivitat.cat:3000/api/images/other/IMG_5095.jpg",
        "https://examenselectivitat.cat:3000/api/images/other/IMG_5088.jpg",
        "https://examenselectivitat.cat:3000/api/images/other/IMG_5101.jpg",
        "https://examenselectivitat.cat:3000/api/images/other/IMG_5111.jpg",
        "https://examenselectivitat.cat:3000/api/images/other/IMG_5085.jpg",
        "https://examenselectivitat.cat:3000/api/images/other/IMG_5106.jpg",
      ],
      stories: false,
      story: 0,
    }
  }
}
</script>

<style scoped>
.ig-stories {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
