<template>
  <div>
    <!--- Valoracions --->
    <h2 class="ml-3 mt-8 font-rubik font-weight-medium">Valoracions</h2>

    <v-slide-group
      class="mt-8"
      :show-arrows="false"
    >
      <v-slide-item
        v-for="(review,n) in reviews"
        :key="n"
        class="pa-6"
      >
        <v-card max-width="300"
                class="pa-6 prevent-select rounded-lg d-flex flex-column rounded-xl mr-5 text-start text-center align-center shadow-sm my-3"
                style="border: thin solid #D9DCE7; height: fit-content"
        >

          <v-avatar class="mb-3" size="40"
                    color="#C6EFFF"
          >
            <img
              v-if="review.image"
              :src="review.image"
            >
            <v-icon v-else size="24" color="#256EFF">{{ mdiAccount }}</v-icon>
          </v-avatar>

          <span class="prevent-select font-weight-medium font-rubik" style="font-size: 16px; color: #282828">{{
              review.name }}</span>


          <v-rating
            v-model="review.rating"
            color="#FFD600"
            background-color="grey lighten-1"
            size="39"
            half-increments
            readonly
            hover
          ></v-rating>

          <span v-if="review.text" class="mt-3 font-rubik prevent-select"
                style="font-size: 16px; color: #676767; line-height: 1.25rem"
          >"{{
              review.text
            }}"</span>


          <div v-else class="d-flex flex-column text-center justify-center prevent-select">
            <div class="d-flex mt-3 align-center">
              <v-icon left color="primary">{{ mdiCheck }}</v-icon>
              <span class="font-rubik"
                    style="font-size: 16px; color: #676767; line-height: 1.25rem"
              >Tornaria a comprar un altre dossier</span>
            </div>

            <div class="d-flex mt-3 align-center">
              <v-icon left color="primary">{{ mdiCheck }}</v-icon>
              <span class="font-rubik"
                    style="font-size: 16px; color: #676767; line-height: 1.25rem"
              >Li recomanaria a un amic</span>
            </div>

          </div>


          <div style="position: absolute; right: 20px; top: 10px">
            <v-icon small color="#B2B4BC">{{ review.via === 'mail' ? mdiEmailOutline : mdiInstagram }}</v-icon>
            <span style="color: #B2B4BC; font-size: 12px"
                  class="ml-1 font-weight-medium">{{ review.via === 'mail' ? 'Email' : 'Instagram' }}</span>
          </div>

        </v-card>

      </v-slide-item>
    </v-slide-group>

  </div>
</template>

<script>
import {mdiInstagram} from '/src/assets/mdi.json'
import {mdiEmailOutline} from '/src/assets/mdi.json'
import {mdiAccount} from '/src/assets/mdi.json';
import {mdiCheck} from '/src/assets/mdi.json';

export default {
  name: "Reviews",
  props: {
    list: {
      default: false
    }
  },
  data() {
    return {
      mdiInstagram: mdiInstagram,
      mdiEmailOutline: mdiEmailOutline,
      mdiAccount: mdiAccount,
      mdiCheck: mdiCheck,
      reviews: [

        {
          name: "Arnau C.",
          rating: 5,
          text: "Us volia donar les gràcies, es la millor manera d’estudiar sele de forma eficient, guanyant molt temps",
          image: "https://examenselectivitat.cat:3000/api/images/other/arnau.jpeg",
          via: 'instagram'
        },
        {
          name: "Xènia S.",
          rating: 5,
          text: "Us volia donar les gràcies infinites pels dossiers! són una passada! em vaan molt bé per estudiar i adaptar les meves respostes al que es demana! tant a matemàtiques com a economia, em va suuper bé tenir les solucions al costat per autocorregir-me. així que altre cop, gràcies",
          image: "https://examenselectivitat.cat:3000/api/images/other/xenia.jpeg",
          via: 'instagram'
        },
        {
          name: "Narcís M.",
          rating: 4.5,
          text: "Moltes gràcies per els dossiers, són una passada! Gràcies per la feina que feu!!",
          image: "https://examenselectivitat.cat:3000/api/images/other/narcis.jpeg",
          via: 'instagram'
        },
        {
          name: "Azucena T.",
          rating: 5,
          text: "M'ha facilitat i em facilita molt la feina a l'hora d'ajudar als meus estudiants a preparar els temes per l'examen de selectivitat.",
          image: "",
          via: 'mail'
        },

        {
          name: "Maria M.",
          rating: 5,
          text: "",
          image: "",
          via: 'mail'
        },
        {
          name: "Esther C.",
          rating: 5,
          text: "",
          image: "",
          via: 'mail'
        },
        {
          name: "Maria R.",
          rating: 4,
          text: "",
          image: "",
          via: 'mail'
        },
        {
          name: "Enric T.",
          rating: 5,
          text: "",
          image: "",
          via: 'mail'
        },
      ]
    }
  }
}
</script>

<style scoped>
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>
