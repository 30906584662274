<template>
  <div>
    <h2
      v-if="dossiers && dossiers.length"
      class="font-rubik font-weight-medium"
      style="font-size: 23px"
    >
      {{ title }}
    </h2>
    <v-row class="mt-2 mb-6">
      <v-col
        v-for="book in dossiers"
        :class="shop ? '' : 'mb-12'"
        cols="12" :sm="shop ? 4 : 6" :md="shop ? 3 : 4" :lg="shop ? 3 : 3"
        :xl="3"
      >
        <v-card :ripple="false"
                color="transparent" flat tile
                class="no-background-hover"
        >
          <v-hover v-slot:default="{ hover }" open-delay="200">
            <v-card
              @click="onSelectSubject(book)"
              :ripple="false"
              style="max-height: fit-content; height: fit-content !important;"
              class="mb-4 rounded-lg" flat tile>
              <v-responsive
                style="min-height: 290px"
                :aspect-ratio="$vuetify.breakpoint.smAndDown ? 'auto' :  1.15">
                <v-img
                  :style="(hover && !shop)  ? 'opacity: 0' : 'opacity: 1'"
                  :ripple="false" class="rounded-lg"
                  style="max-height: 300px; transition: all 0.2s; transition-delay: 0.1s; height: 100%"
                  :src="book.image"
                ></v-img>
                <v-img
                  :style="hover && !shop ? 'opacity: 1' : 'opacity: 0'"
                  style="max-height: 300px; transition: opacity 0.2s; transition-delay: 0.1s; position: absolute; left:
                 0; top: 0; height: 100%"
                  :ripple="false" class="rounded-lg"
                  :src="book.imageHover"
                ></v-img>
              </v-responsive>

              <span
                :style="!hover && !shop ? 'opacity: 1' : 'opacity: 0'"
                style="position: absolute; top: 12px; left: 10px; font-size: 15px; transition: all 0.2s; transition-delay: 0.1s;"
                class="grey--text font-rubik"
              >
                      {{ book.pages }} pàg.
                    </span>
              <v-chip
                v-if="book && book.oldPrice"
                color="red" small
                style="position: absolute; top: 12px; right: 10px; font-size: 12px; transition: opacity 0.2s; transition-delay: 0.1s;"
                class="white--text font-rubik rounded font-weight-medium px-2"
              >
                -{{ Math.round((1 - (book.price / book.oldPrice)) * 100) }}%
              </v-chip>
            </v-card>
          </v-hover>

          <div class="d-flex">
            <div
              style="flex: 1; cursor: pointer"
              @click="onSelectSubject(book)"
              class="d-flex flex-column">
              <v-hover v-slot:default="{ hover }">
                <div>
              <span class="font-rubik font-weight-medium"
                    :class="hover ? 'amber--text text--darken-1' : ''"
                    style="font-size: 17px"
              >{{ book.new_subject_name || book.subject_name }}</span>
                  <span
                    v-if="false"
                    class="font-rubik"
                    :class="hover ? 'amber--text text--darken-1' : ''"
                    style="font-size: 17px"
                  > Exàmens</span>
                </div>
              </v-hover>
              <div
                v-if="book"
                class="d-flex align-center">
                <span class="font-weight-regular font-rubik"
                      :style="book.oldPrice ? 'color: red; font-size: 18px !important' : ''"
                      style="font-size: 15px !important;">{{ book.price }}€</span>
                <span
                  v-if="book.oldPrice"
                  class="font-weight-regular font-rubik ml-3"
                  style="font-size: 15px !important; text-decoration: line-through"
                >
                {{ book.oldPrice }}€
              </span>
              </div>
            </div>
          </div>


          <v-hover v-slot:default="{ hover }">
            <v-btn
              @click="addToCart(book)"
              height="38"
              style="max-height: 42px !important; width: 100%"
              :class="hover ? 'shadow-small' : 'shadow-sm'"
              depressed
              :color="showBuyAlert && boughtBook === book ? 'grey lighten-2' : 'primary'"
              class="text-none font-weight-medium body-2 mb-3 mt-3 rounded-lg"
              :style="$vuetify.breakpoint.xs ? 'padding-top: 22px; padding-bottom: 22px' : 'max-width: 250px'"
            >
              <v-slide-y-reverse-transition
                leave-absolute
              >

                <v-icon
                  v-if="showBuyAlert && boughtBook === book"
                >
                  {{ mdiCheck }}
                </v-icon>
                <span v-else class="font-rubik"
                      style="font-size: 15px"
                >Afegir a la cistella</span>
              </v-slide-y-reverse-transition>
            </v-btn>
          </v-hover>


        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      :key="refreshAlert"
      v-model="showBuyAlert"
      timeout="3000"
      height="auto"
      app
      bottom
      light
      right
      transition="slide-x-reverse-transition"
      class="rounded-lg shadow-sm"
    >
      <div class="d-flex align-center">
        <v-card color="transparent"
                class="d-flex align-center justify-center rounded-circle mx-auto mr-2" height="26"
                width="26" flat>
          <v-icon color="primary">{{ mdiCheck }}</v-icon>
        </v-card>
        <span v-if="boughtBook && cart"><span class="font-weight-medium font-rubik">{{
            boughtBook.subject_name
          }}</span> ha sigut afegit a la cistella ({{ cart }} {{ cart > 1 ? 'productes' : 'producte' }})</span>
      </div>
    </v-snackbar>

    <alerta-literatura
      :literaturaAlert.sync="literaturaAlert"
      :literaturaAlertMsg="literaturaAlertMsg"
      :books="dossiers"
      @addToCart="addToCart"
    ></alerta-literatura>

  </div>
</template>

<script>
import {mdiCartPlus} from '/src/assets/mdi.json'
import {mdiCheck} from '/src/assets/mdi.json'
import AlertaLiteratura from "./AlertaLiteratura.vue";

export default {
  name: "ItemsRow",
  components: {AlertaLiteratura},
  props: {
    title: String,
    items: Array,
    shop: {
      default: false
    },
    apunts: {
      default: false
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_AXIOS_URI,
      showBuyAlert: false,
      refreshAlert: 0,
      boughtBook: null,
      mdiCartPlus: mdiCartPlus,
      mdiCheck: mdiCheck,
      literaturaAlert: false,
      literaturaAlertMsg: ""
    }
  },
  computed: {
    cart() {
      return this.$root.cart
    },
    dossiers() {
      return this.items;
    }
  },
  methods: {
    deApostrof(assig) {
      const lletra = assig.charAt(0).toLowerCase()
      return lletra === 'a' || lletra === 'e' || lletra === 'i' || lletra === 'o' || lletra === 'u' || lletra === 'h' ? "d'" + assig : 'de ' + assig
    },
    async onSelectSubject(book) {
      this.selectedSubject = book.subject_id;
      this.selectedSubjectName = book.subject_name;

      this.$router.push({path: '/dossiers-selectivitat/book/' + book.book_id})
    },

    async addToCart(book) {
      this.showBuyAlert = false;
      const book_id = book.book_id;
      this.$root.cart++;

      // Alert LITERATURA
      if (book_id === 29) {
        this.literaturaAlert = true;
        this.literaturaAlertMsg = "CASTELLANA"
      } else if (book_id === 30) {
        this.literaturaAlert = true;
        this.literaturaAlertMsg = "CATALANA"
      }

      // ADD TO CART
      let list = this.$cookies.get('cart');
      if (!list[book_id]) list[book_id] = 0;
      list[book_id]++
      this.$cookies.set('cart', list)
      this.boughtBook = book;
      this.showBuyAlert = true;
      this.$emit('addItem')

      setTimeout(() => {
        this.refreshAlert++;
      }, 200)
    },
  }
}
</script>

<style scoped>

/**
 * ----------------------------------------
 * animation flip-in-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
    transform: rotateY(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
}

@keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
    transform: rotateY(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
}


.flip-in-ver-right {
  -webkit-animation: flip-in-ver-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: flip-in-ver-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transition-delay: 0.2s;
}
</style>
