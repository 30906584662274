
import {defineComponent} from 'vue'
import Shop from "../Shop";

export default defineComponent({
  name: "ArrivadaEnviament",
  mounted() {
    this.i = Shop.Instance;
    this.arrivesAt = this.i.arrivesAt(3);
    this.arrivesAtFast = this.i.arrivesAt(2);

    this.updateTime();  // Call the updateTime method when the component is mounted
    setInterval(() => {
      const arrivesAtOld = this.arrivesAt + " "
      this.updateTime();  // Update the currentTime every minute
      if (arrivesAtOld !== this.arrivesAt) {
        this.updated = true
        setTimeout(() => {
          this.updated = false
        }, 1000)
      }
    }, 30000);  // 60000 milliseconds = 1 minute
  },
  data() {
    return {
      i: null,
      arrivesAt: "",
      arrivesAtFast: "",
      updated: false,
      currentTime: new Date()  // Initialize the reactive property with the current time
    }
  },
  computed: {
    timeTo11() {
      // const endtime = new Date(this.currentTime);
      // endtime.setDate(this.currentTime.getDate() + 1);
      if (this.i !== null) {
        const endtime = this.i.nextWorkingDay();
        endtime.setHours(11, 0, 0);

        const total = Date.parse(endtime) - Date.parse(this.currentTime);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        if (hours < 24) {
          let text = "";
          if (hours > 0) {
            if (hours === 1)
              text += `${hours} hora`;
            else
              text += `${hours} hores`;
          }

          const minutes = Math.floor((total / 1000 / 60) % 60);
          if (minutes > 0) {
            if (minutes === 1)
              text += ` ${minutes} min`;
            else
              text += ` ${minutes} mins`;
          }
          return text;
        } else {
          return null;
        }
      }
      return null
    }
  },
  methods: {
    updateTime() {
      this.currentTime = new Date();  // Update currentTime to the current time
    }
  },
})
